import merge from 'lodash/merge';
import getColorByTraceKey from './getColorByTraceKey';

export default function mapDefaultTraceSettings(trace, conf) {
  if (conf && typeof conf === 'object') {
    trace = merge(trace, conf);
  }
  if (['strikeZone', 'strikeZoneSectors'].includes(trace.key)) {
    trace.type = 'scatter';
    trace.mode = 'lines';
    trace.hoverinfo = 'skip';
    trace.line = {
      color: getColorByTraceKey(trace.key),
      width: 4,
    };
  }

  if (!trace.hasOwnProperty('hoverinfo')) {
    trace.hoverinfo = 'none';
    // trace.hovertemplate = '';
  }

  if (!trace.hasOwnProperty('type')) {
    trace.type = 'scatter';
    trace.mode = 'markers';
    trace.hoverinfo = 'all';
    trace.marker = { size: 8, opacity: 0.7, line: { width: 0.5 } };
  }

  if (trace.type === 'heatmap') {
    trace.hoverinfo = 'x+y+z';
    // TODO:implement proper unitsymbols later
    trace.hovertemplate = 'x: %{x:.2f}<br>y: %{y:.2f}<br>Pitches: %{z}<extra></extra>';
    trace.colorscale = [
      [0, 'rgba(255,255,255,0)'],
      [0.01, 'rgba(244, 208, 63, 1)'],
      [0.5, 'rgba(249, 105, 14, 1)'],
      [1, 'rgba(255,0,0,1)'],
    ];
  }
  return trace;
}
