const determineChartAxisRange = (values, isSymmetric, minRange, padding, snapToGridOfSize, axisMin = 0) => {
  if (!(values instanceof Array)) {
    throw new TypeError('values argument must contain an array of values for given axis');
  }

  if (typeof isSymmetric !== 'boolean') {
    throw new TypeError('isSymmetric argument must contain boolean value');
  }

  if (typeof minRange !== 'number') {
    throw new TypeError('minRange argument must contain number');
  }

  if (typeof padding !== 'number') {
    throw new TypeError('padding argument must contain number');
  }

  if (typeof snapToGridOfSize !== 'number') {
    throw new TypeError('snapToGridOfSize argument must contain number');
  }

  let min = axisMin;
  let max = minRange;

  if (isSymmetric) {
    min = minRange / -2;
    max = minRange / 2;
  }

  let seriesMax = 0;
  let seriesMin = min;
  // verify values
  if (values.length) {
    seriesMin = Math.min(...values) - padding;
    seriesMax = Math.max(...values) + padding;
  }

  if (isSymmetric) {
    const maxAbs = Math.max(max, Math.abs(seriesMin), Math.abs(seriesMax));
    min = -maxAbs;
    max = maxAbs;
  } else {
    min = Math.min(min, seriesMin);
    max = Math.max(max, seriesMax);
  }

  if (snapToGridOfSize) {
    min = Math.floor(min / snapToGridOfSize) * snapToGridOfSize;
    max = Math.ceil(max / snapToGridOfSize) * snapToGridOfSize;
  }

  return { min, max };
};

export default determineChartAxisRange;
